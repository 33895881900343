import { useState, useEffect, Fragment, useCallback } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Router from 'next/router'
import ProgressBar from '@badrap/bar-of-progress'
import { ResizeObserver } from '@juggle/resize-observer'
import TagManager from 'react-gtm-module'
import tradly from 'tradly'
import mixpanel from 'mixpanel-browser'
import axios from 'axios'
import { Provider } from 'react-redux'
import { AppProvider } from '@/context_store/AppContext'
import { GlobalPopupProvider } from '@/context/PopupContext'
import store from '@/redux/Store'
import { Title } from '@/components/Title'
import Header from '@/partials/Header'
import GlobalSidebar from '@/Shared/Sidebar/GlobalSidebar'
import LanguageCurrencyInModal from '@/components/Auth/AdditionalInformation/LanguageCurrencyInModal'
import { getAuthKey, getRefreshKey } from 'constant/functions'
import { logout_tradly } from '@/components/Auth/Logout'

import '../css/fonts.css'
import '../css/main.css'
import 'focus-visible'
import 'intersection-observer'
import 'react-image-lightbox/style.css'
import 'rc-slider/assets/index.css'
import 'swiper/css'
import 'swiper/css/navigation'

import socialCardLarge from '@/img/tradly/Tradly MEta image.png'
import { SearchProvider } from '@/components/Search'

if (typeof window !== 'undefined' && !('ResizeObserver' in window)) {
  window.ResizeObserver = ResizeObserver
}

const progress = new ProgressBar({
  size: 2,
  color: '#38bdf8',
  className: 'bar-of-progress',
  delay: 100,
})

if (typeof window !== 'undefined') {
  progress.start()
  progress.finish()
}

Router.events.on('routeChangeStart', progress.start)
Router.events.on('routeChangeComplete', progress.finish)
Router.events.on('routeChangeError', progress.finish)

export default function App({ Component, pageProps }) {
  const [navIsOpen, setNavIsOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    tradly.init.config({
      token: '',
      environment: process.env.ENVIRONMENT,
    })
    mixpanel.init(process.env.MIXPANEL_ID, { debug: false })
  }, [])

  // auth key checkup function
  const checkAuth = useCallback(async () => {
    const refreshKey = getRefreshKey()
    const authKey = getAuthKey()

    if (authKey && !refreshKey) {
      logout_tradly()
    }

    if (!refreshKey) {
      return
    }

    try {
      await axios.get('/api/refresh_token')
    } catch (error) {
      logout_tradly()
    }
  }, [])

  useEffect(() => {
    checkAuth()
    const handleFocus = () => {
      checkAuth()
    }

    window.addEventListener('focus', handleFocus)
    router.events.on('routeChangeStart', checkAuth)

    return () => {
      window.removeEventListener('focus', handleFocus)
      router.events.off('routeChangeStart', checkAuth)
    }
  }, [router, checkAuth])

  useEffect(() => {
    if (!navIsOpen) return
    const handleRouteChange = () => setNavIsOpen(false)
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => Router.events.off('routeChangeComplete', handleRouteChange)
  }, [navIsOpen])

  const Layout = Component.layoutProps?.Layout || Fragment
  const layoutProps = Component.layoutProps?.Layout
    ? { layoutProps: Component.layoutProps, navIsOpen, setNavIsOpen }
    : {}
  const meta = Component.layoutProps?.meta || {}
  const description = meta.metaDescription || meta.description || 'Default description'

  if (router.pathname.startsWith('/examples/')) {
    return <Component {...pageProps} />
  }

  const section =
    meta.section ||
    Object.entries(Component.layoutProps?.Layout?.nav ?? {}).find(([, items]) =>
      items.find(({ href }) => href === router.pathname)
    )?.[0]

  const shouldShowHeader =
    !router.pathname.startsWith('/launchpad') &&
    !router.pathname.startsWith('/editor') &&
    !router.pathname.startsWith('/add_page') &&
    !router.pathname.startsWith('/edit_page') &&
    !router.pathname.startsWith('/workspace') &&
    !router.pathname.startsWith('/signup') &&
    !router.pathname.startsWith('/onboarding') &&
    !router.pathname.startsWith('/embed') &&
    !router.pathname.startsWith('/info')

  return (
    <>
      <Title suffix="Tradly">{meta.metaTitle || meta.title}</Title>
      <Head>
        <meta name="description" content={description} />
        <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
        <meta key="twitter:site" name="twitter:site" content="@TradlyPlatform" />
        <meta key="twitter:description" name="twitter:description" content={description} />
        <meta
          key="twitter:image"
          name="twitter:image"
          content={`https://tradly.app${socialCardLarge}`}
        />
        <meta key="twitter:creator" name="twitter:creator" content="@TradlyPlatform" />
        <meta key="og:url" property="og:url" content={`https://tradly.app${router.asPath}`} />
        <meta key="og:type" property="og:type" content="website" />
        <meta key="og:description" property="og:description" content={description} />
        <meta key="og:image" property="og:image" content={`https://tradly.app${socialCardLarge}`} />
        <link rel="alternate" type="application/rss+xml" title="RSS 2.0" href="/feeds/feed.xml" />
        <link rel="alternate" type="application/atom+xml" title="Atom 1.0" href="/feeds/atom.xml" />
        <link rel="alternate" type="application/json" title="JSON Feed" href="/feeds/feed.json" />
      </Head>

      <Provider store={store}>
        <SearchProvider>
          <AppProvider>
            <GlobalPopupProvider>
              {shouldShowHeader && (
                <>
                  <LanguageCurrencyInModal />
                  <Header
                    hasNav={Boolean(Component.layoutProps?.Layout?.nav)}
                    navIsOpen={navIsOpen}
                    onNavToggle={(isOpen) => setNavIsOpen(isOpen)}
                    title={meta.title}
                    section={section}
                  />
                </>
              )}

              {/*  */}
              <Layout {...layoutProps}>
                <Component section={section} {...pageProps} />
              </Layout>
            </GlobalPopupProvider>
            <GlobalSidebar />
          </AppProvider>
        </SearchProvider>
      </Provider>
    </>
  )
}
