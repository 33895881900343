import { tagTypes } from '@/redux/api/TagTypes'
import { apiSlice } from '@/redux/api/apiSlice'

export const userApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Get  user details
    getUserDetails: builder.query({
      query: () => {
        return `/user/details`
      },
      providesTags: [tagTypes.user_details],
    }),

    //Get  tenant details
    getTenantDetails: builder.query({
      query: () => {
        return `/user/tenant_details`
      },
      providesTags: [tagTypes.user_tenant_details],
    }),

    //update  tenant details
    updateTenantDetails: builder.mutation({
      query: ({ data, tenant_name }) => {
        return { url: `/user/update_tenant_details`, method: 'PATCH', body: { data, tenant_name } }
      },
      providesTags: [tagTypes.user_tenant_details],
    }),

    //Get  tenant details
    getTenantDetails: builder.query({
      query: () => {
        return `/user/tenant_details`
      },
      providesTags: [tagTypes.user_tenant_details],
    }),

    //Get  tenant details
    getTenantList: builder.query({
      query: () => {
        return `/user/tenant_list`
      },
      providesTags: [tagTypes.user_tenant_list],
    }),

    //Get  tenant details
    getDomainsList: builder.query({
      query: () => {
        return `v2/tenant/sites_list`
      },
      providesTags: [tagTypes.domains_list],
    }),
  }),
})

export const {
  useGetUserDetailsQuery,
  useGetTenantDetailsQuery,
  useGetTenantListQuery,
  useGetDomainsListQuery,
  useUpdateTenantDetailsMutation,
} = userApi
