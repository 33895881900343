import { useDispatch, useSelector } from 'react-redux'
import React, { useRef, useEffect } from 'react'
import { onCloseSidebar, sidebarSelector } from '@/redux/features/sidebar/SidebarSlice'
import Transition from '@/utils/Transition'
import AllIcons from 'constant/AllIcons'
import { cn } from '@/utils/classNames'

function GlobalSidebar({}) {
  const modalContent = useRef(null)
  const dispatch = useDispatch()

  //
  const {
    sidebar_id,
    isSidebarOpen,
    modal_section_styles,
    modal_inside_section_styles,
    is_close_by_outer_click,
    sidebar_inside_component,
    close_button_style,
    close_button_div_style,
  } = useSelector(sidebarSelector)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar_id || modalContent.current.contains(target)) return
      is_close_by_outer_click && dispatch(onCloseSidebar())
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!isSidebarOpen || keyCode !== 27) return
      is_close_by_outer_click && dispatch(onCloseSidebar())
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed inset-0 bg-gray-900 bg-opacity-30  z-50 transition-opacity"
        show={isSidebarOpen}
        enter="transition ease-in duration-400"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-400 "
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={sidebar_id}
        className={[
          modal_section_styles
            ? modal_section_styles
            : `fixed inset-0 z-[60] overflow-hidden flex items-center    transform justify-end`,
          ,
        ].join(' ')}
        role="dialog"
        aria-modal="true"
        show={isSidebarOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className={
            modal_inside_section_styles
              ? modal_inside_section_styles
              : [
                  'bg-white rounded shadow-lg overflow-auto   max-h-full  scrollbar-thin    scrollbar-track-transparent  scrollbar-thumb-transparent max-w-lg w-full',
                ].join(' ')
          }
        >
          <div className="flex w-full h-full ">
            <div className={close_button_div_style ?? ''}>
              {/* close button */}
              <button
                className={cn('  ml-3 mt-3   flex flex-col gap-1  ', close_button_style)}
                onClick={() => {
                  dispatch(onCloseSidebar())
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 cursor-pointer"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <span className="text-sm  font-light">ESC</span>
              </button>
            </div>
            {sidebar_inside_component}
          </div>
        </div>
      </Transition>
    </>
  )
}

export default GlobalSidebar
