import React, { useState, useRef, useEffect } from 'react'
import Link from 'next/link'
import Dropdown from '@/utils/Dropdown'
import Transition from '@/utils/Transition'
import Router from 'next/router'
import { useRouter } from 'next/router'
import { getAuthKey } from 'constant/functions'
import UserMenu from './UserMenu'
import TopNotification from '@/components/TopNotification'

function Header({ hasNav = false, navIsOpen, onNavToggle, title, section }) {
  //
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [top, setTop] = useState(true)
  const [hideNt, setHideNt] = useState(false)

  const trigger = useRef(null)
  const mobileNav = useRef(null)

  //
  const router = useRouter()

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target))
        return
      setMobileNavOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  //close nav after load new page

  useEffect(() => {
    if (!mobileNav) return
    function handleRouteChange() {
      setMobileNavOpen(false)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [mobileNav])

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  // effect for TopNotification
  // useEffect(() => {
  //   if (localStorage.getItem('hideNt')) {
  //     setHideNt(JSON.parse(localStorage.getItem('hideNt')))
  //   }
  // }, [])

  return (
    <>
      {/* {!hasNav && ( */}

      {/* {
        <div className={hideNt ? 'hidden' : 'block'}>
          <TopNotification setHideNt={setHideNt} hideNt={hideNt} />
        </div>
      } */}

      <header
        className={` sticky top-0   md:h-20 w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out bg-white ${
          !top && '  blur  shadow-lg'
        }`}
      >
        <div className="  mx-auto px-5 sm:px-6">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Site branding */}
            <div className="flex-shrink-0 mr-4">
              {/* Logo */}
              <Link href="/">
                <div className=" flex items-center justify-start gap-2" aria-label="Tradly">
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 126 126"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M63 0C97.7939 0 126 28.2061 126 63C126 97.7939 97.7939 126 63 126C28.2061 126 0 97.7939 0 63C0 28.2061 28.2061 0 63 0Z"
                      fill="url(#paint0_linear)"
                    />
                    <path
                      opacity="0.5"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M63 119C93.9279 119 119 93.9279 119 63C119 32.0721 93.9279 7 63 7C32.0721 7 7 32.0721 7 63C7 93.9279 32.0721 119 63 119Z"
                      stroke="white"
                      stroke-width="1.4"
                    />
                    <path
                      opacity="0.5"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M63 105C86.196 105 105 86.196 105 63C105 39.804 86.196 21 63 21C39.804 21 21 39.804 21 63C21 86.196 39.804 105 63 105Z"
                      stroke="white"
                      stroke-width="1.4"
                    />
                    <path
                      d="M108.282 44.2442C105.799 38.2551 102.162 32.8652 97.6482 28.3518C88.7809 19.4845 76.5309 14 63 14C49.469 14 37.219 19.4845 28.3517 28.3518C23.8383 32.8652 20.2012 38.2551 17.7178 44.2442"
                      stroke="white"
                      stroke-width="15.4"
                      stroke-linecap="round"
                    />
                    <path
                      d="M63.0001 14.0001V111.222"
                      stroke="white"
                      stroke-width="15.4"
                      stroke-linecap="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1="126"
                        y1="0"
                        x2="126"
                        y2="126"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#2BDBC0" />
                        <stop offset="1" stop-color="#13B58C" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className=" font-bold text-2xl">Tradly</span>
                </div>
              </Link>
            </div>

            {/* Desktop navigation */}
            <nav className="hidden md:flex md:flex-grow">
              {/* Desktop menu links */}
              <ul className="flex flex-grow justify-end flex-wrap items-center text-sm font-medium">
                <Dropdown
                  title="Product"
                  className="origin-top-right absolute top-full right-0 w-40 bg-white py-2 ml-4 rounded shadow-lg"
                >
                  {/* 2nd level: hover */}
                  <li>
                    <Link href="/building-blocks">
                      <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                        Features
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/templates">
                      <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                        Templates
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/integrations">
                      <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                        Integrations
                      </a>
                    </Link>
                  </li>
                </Dropdown>

                <li>
                  <Link href="/docs/setup">
                    <a className="text-gray-800 font-medium hover:text-gray-900 text-base px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                      Docs
                    </a>
                  </Link>
                </li>

                <li>
                  <Link href="/pricing/marketplace">
                    <a className="text-gray-800 font-medium hover:text-gray-900 text-base px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                      Pricing
                    </a>
                  </Link>
                </li>

                {/* 1st level: hover */}
                <Dropdown
                  title="Resources"
                  className="origin-top-right absolute top-full right-0 w-40 bg-white py-2 ml-4 rounded shadow-lg"
                >
                  <li>
                    <Link href="/tutorials">
                      <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                        Tutorials
                      </a>
                    </Link>
                  </li>
                  {/* 2nd level: hover */}
                  <li>
                    <Link href="https://community.tradly.app/">
                      <a
                        target="_blank"
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        Community Forum
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/blog">
                      <a className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">
                        Releases
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="https://developer.tradly.app/">
                      <a
                        target="_blank"
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        API Reference
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://www.npmjs.com/package/tradly">
                      <a
                        target="_blank"
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        JS SDK
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://github.com/TRADLY-PLATFORM">
                      <a
                        target="_blank"
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        Open Source Starter Kits
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link href="/docs/testingapp">
                      <a
                        target="_blank"
                        className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                      >
                        Testing Apps
                      </a>
                    </Link>
                  </li>

                  {/* <li>
                  <Link href="/support">
                    <a className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">
                      Support center
                    </a>
                  </Link>
                </li> */}
                </Dropdown>
              </ul>

              {/* Desktop sign in links */}
              {getAuthKey() === undefined ? (
                <ul className="flex flex-grow justify-end flex-wrap items-center">
                  <li>
                    <Link href={'/support'}>
                      <a className="hidden lg:flex border-r-2   border-gray-400 px-4 mr-2 py-0.5 text-gray-800 font-medium hover:text-gray-900 text-base   items-center transition duration-150 ease-in-out">
                        Contact Sales
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={`/signin`}>
                      <a className=" hidden lg:flex  text-gray-800 font-medium hover:text-gray-900 text-base px-5 py-3   items-center transition duration-150 ease-in-out">
                        Sign in
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href={`/signup`}>
                      <a className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                        <span>Start Free</span>
                        <svg
                          className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                            fillRule="nonzero"
                          />
                        </svg>
                      </a>
                    </Link>
                  </li>
                </ul>
              ) : (
                <ul className="flex flex-grow justify-end flex-wrap items-center">
                  <li>
                    <Link href={'/support'}>
                      <a className=" hidden lg:flex font-medium border-r-2  border-gray-400 text-gray-500 hover:text-gray-900 px-4 mr-3 py-0.5   items-center transition duration-150 ease-in-out">
                        Contact Sales
                      </a>
                    </Link>
                  </li>
                  <UserMenu align="right" />
                </ul>
              )}
            </nav>

            {/* Mobile menu */}
            <div className="flex md:hidden">
              {/* Hamburger button */}
              <UserMenu align="right" />

              {getAuthKey() === undefined && (
                <ul className="flex flex-grow justify-end flex-wrap items-center mr-2">
                  <li>
                    <Link href={`/signup`}>
                      <a className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                        <span>Start Free</span>
                        <svg
                          className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                            fillRule="nonzero"
                          />
                        </svg>
                      </a>
                    </Link>
                  </li>
                </ul>
              )}
              <button
                ref={trigger}
                className={`hamburger ${mobileNavOpen && 'active'}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
              >
                <span className="sr-only">Menu</span>
                <svg width="24" height="24" fill="none" aria-hidden="true">
                  <path
                    d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              {/*Mobile navigation */}
              <div ref={mobileNav}>
                <Transition
                  show={mobileNavOpen}
                  tag="nav"
                  id="mobile-nav"
                  className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                  enter="transition ease-out duration-200 transform"
                  enterStart="opacity-0 -translate-y-2"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-out duration-200"
                  leaveStart="opacity-100"
                  leaveEnd="opacity-0"
                >
                  <ul className="px-5 py-2 pb-20">
                    <li className="py-2 my-2 border-t border-b border-gray-200">
                      <span className="flex text-gray-800 font-medium hover:text-gray-900 text-base py-2">
                        Product
                      </span>
                      <ul className="pl-4">
                        <li>
                          <Link href="/building-blocks">
                            <a className="flex text-gray-600 hover:text-gray-900 py-2">Features</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/templates">
                            <a className="flex text-gray-600 hover:text-gray-900 py-2">Templates</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/integrations">
                            <a className="flex text-gray-600 hover:text-gray-900 py-2">
                              Integrations
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link href="/tutorials">
                        <a className="flex text-gray-800 font-medium hover:text-gray-900 text-base py-2">
                          Tutorials
                        </a>
                      </Link>
                    </li>

                    <li>
                      <Link href="/pricing/marketplace">
                        <a className="flex text-gray-800 font-medium hover:text-gray-900 text-base py-2">
                          Pricing
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/docs/setup">
                        <a className="text-gray-800 font-medium hover:text-gray-900 text-base   lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                          Docs
                        </a>
                      </Link>
                    </li>
                    <li className="py-2 my-2 border-t border-b border-gray-200">
                      <span className="flex text-gray-800 font-medium hover:text-gray-900 text-base py-2">
                        Resources
                      </span>
                      <ul className="pl-4">
                        <li>
                          <Link href="https://community.tradly.app/">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              Community Forum
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="https://developer.tradly.app/">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              API reference
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="https://www.npmjs.com/package/tradly">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              JS SDK
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="https://github.com/TRADLY-PLATFORM">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              Open Source Apps
                            </a>
                          </Link>
                        </li>

                        <li>
                          <Link href="/docs/testingapp">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              Testing Apps
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/blog">
                            <a
                              target="_blank"
                              className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight"
                            >
                              Releases
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <Link href="/support">
                        <a className="flex text-gray-800 font-medium hover:text-gray-900 text-base py-2">
                          Contact Sales
                        </a>
                      </Link>
                    </li>

                    {getAuthKey() === undefined && (
                      <>
                        <li>
                          <Link href={`/signin`}>
                            <a className="flex  w-full text-gray-800 font-medium hover:text-gray-900 text-base py-2 justify-center">
                              Sign in
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href={`/signup`}>
                            <a className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                              <span> Start Free </span>
                              <svg
                                className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                                viewBox="0 0 12 12"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                                  fill="#999"
                                  fillRule="nonzero"
                                />
                              </svg>
                            </a>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </Transition>
              </div>
            </div>
          </div>{' '}
          {hasNav && (
            <div className="flex items-center p-4 border-b border-gray-900/10 md:hidden dark:border-gray-50/[0.06]">
              <button
                type="button"
                onClick={() => onNavToggle(!navIsOpen)}
                className="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300"
              >
                <span className="sr-only">Navigation</span>
                <svg width="24" height="24">
                  <path
                    d="M5 6h14M5 12h14M5 18h14"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              {title && (
                <ol className="ml-4 flex text-sm leading-6 whitespace-nowrap min-w-0">
                  {section && (
                    <li className="flex items-center">
                      {section}
                      <svg
                        width="3"
                        height="6"
                        aria-hidden="true"
                        className="mx-3 overflow-visible text-gray-400"
                      >
                        <path
                          d="M0 0L3 3L0 6"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </li>
                  )}
                  <li className="font-semibold text-gray-900 truncate dark:text-gray-200">
                    {title}
                  </li>
                </ol>
              )}
            </div>
          )}
        </div>
      </header>
    </>
  )
}

export default Header
